import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Certificates from "./Certificates";
import Comments from "./Comments";
import ProfileBasicInfo from "./ProfileBasicInfo";
import ProfileStatistics from "./ProfileStatistics";
import style from './index.module.css'
import UserCards from "./Card";
import Courses from "./Courses";

const Profile = () => {
  const { user_id } = useParams();
  const currentUser = useSelector((store) => store.AppReducer.user);
  const token = useSelector((store) => store.AppReducer.token);
  const history = useHistory();

  useEffect(() => {
    if (currentUser?.role !== "Admin" && currentUser?.id !== user_id)
      history.push(`/profile/view/${user_id}/public`); console.log('token', token)
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <Container maxWidth="xl" sx={{
          gap: "2rem",
          display: "flex",
          flexDirection: "column",
        }}>
          <ProfileBasicInfo {...{ style }} />
          {currentUser?.role != "Admin" && <UserCards />}
          <ProfileStatistics />
          <Courses />
          <Certificates />
          <Comments />
        </Container>
      </div>
    </div>
  );
};

export default Profile;
