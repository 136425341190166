import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Post } from "../../../utils/request";
import { Show } from "../../../actions/SnackbarActions";
import FormBody from "./FormBody";

const ProfileEditAdmin = () => {

    const [loading, SetLoading] = useState(false);

    const { user_id } = useParams();
    const currentUser = useSelector(store => store.AppReducer.user);
    const dispatch = useDispatch();
    const history = useHistory();

    const Submit = async data => {

        let form = new FormData();

        form.append("id", user_id);
        form.append("name", data?.name);
        form.append("email", data?.email);
        form.append("password", data?.password);
        form.append("phone", data?.phone);
        form.append("description", data?.description);
        form.append("isTeamMember", data?.isTeamMember);
        form.append("role_id", data?.role);
        if (data?.image instanceof File) form.append("profile_image", data?.image);
        if (data?.signature) form.append("signature", data?.signature);
        SetLoading(true);
        let response = await Post(`auth/edit_profile`, form);
        SetLoading(false);
        dispatch(Show({
            show: true,
            severity: response?.severity,
            message: response?.message
        }));
        
        if (response?.status === true) {
            // if (data?.password) {
            //     // console.log("fir");
            //     // await localStorage.removeItem("user");
            //     // await dispatch({type: "login", payload: { user: {}, token: null }});
            //     // history.push(`/login`);
            // } else if (response?.user) {
            //     console.log("ese");
            //     localStorage.setItem("user", JSON.stringify(response?.user));
            // }
            history.push(`/profile/view/${user_id}`);
        } else response?.redirectTo && history.push(response?.redirectTo);
    }

    useEffect(() => {
        if (currentUser?.role !== "Admin" && currentUser?.id !== user_id) history.push(`/profile/view/${user_id}/public`);
    }, []);

    return (
        <div className="profile-edit">
            <div className="card">
                <div className="card-body">
                    <FormBody
                        OnConfirm={Submit}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}

export default ProfileEditAdmin;