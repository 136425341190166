import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Get } from "utils/request";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

const useCourseViewBody = (props) => {
  const { course_id, file_id } = useParams();
  const _history = useHistory();
  const dispatch = useDispatch();

  const { data: _courseData, refetch,isLoading } = useQuery(
    ["course", course_id],
    async () => await Get(`courses-main?id=${course_id}`),
    {
      enabled: Boolean(course_id),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.httpCode !== 200) throw new Error("failed to get course");

        const { course } = data;
        console.log(data)
        if (!course?.available) {
          // alert("Curs not available");
          _history.replace("/my-courses")
          dispatch(
            Show({
              show: true,
              severity: "error",
              message: !data.message?'CURSO EXPIRADO':'',
              // buttonActionText: "Ok",
              // buttonAction: () => _history.replace("/my-courses"),
            })
          );
          return;
        }

        // } else if (!course?.usercourse) _history.replace(`/courses/adquire/${course_id}`);

        if (file_id === undefined) {
          const { course } = data;
          if (course?.lastSeenLesson)
            _history.replace(
              `/courses/${course_id}/${course?.lastSeenLesson?.file_id}`
            );
          else if (course?.first_file_id)
            _history.replace(`/courses/${course_id}/${course?.first_file_id}`);
        }
      },
      onError: (data) => {
        dispatch(
          Show({
            show: true,
            severity: "error",
            message: data.message,
            buttonActionText: "Ok",
            buttonAction: () => _history.replace("/my-courses"),
          })
        );
      },
    }
  );
  const { data: _fileData, isLoading: fileIsLoading } = useQuery(
    ["file", file_id],
    async () => {
      return await Get(`lessons/files?id=${file_id}`);
    },
    {
      enabled: Boolean(file_id),
      refetchOnWindowFocus: false,
      // onSuccess: (data) =>
    }
  );

  const { course = {} } = _courseData || {};

  const { lesson = {}, file = {}, next = {}, prev = {} } = _fileData || {};

  const { userfile: { video_time: startTime } = {} } = file || {};

  return {
    course,
    fileData: {
      lesson,
      file,
      next,
      prev,
      fileIsLoading,
    },
    startTime,
    file_id,
    refetch,
    isLoading
  };
};

export default useCourseViewBody;
