import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { Get, Post } from "utils/request";
import { useParams } from "react-router-dom";

const useCourses = () => {

    const user = useSelector(store => store.AppReducer.user);

    const { user_id } = useParams();
    const { data, isLoading: certificateIsLoading } = useQuery(["user-courses-profile-", user_id], async () => await Get(`user-course/get-users-course/${user_id}?all_groups=1`));
    const {
        courses
    } = data || {};

    const dispatch = useDispatch();

   

    return {
        courses: {
            courses,
            isLoading: certificateIsLoading
        },
    };
}

export default useCourses;