import React, { useCallback, useEffect, useRef, useState } from "react";
import { URL } from "../variables";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header";
import Aside from "../SideBar/Aside";

import "../SideBar/styles/App.scss";
import { login, logout, mudarDados } from "../actions/AppActions";
import { Redirect, Route, Switch, useHistory } from "react-router";
import EmailsLog from "../pages/LogPages/Emails";
import AccountsLog from "../pages/LogPages/Accounts";
import GeneralConfig from "../pages/ControlPanel/General";
import LandingPageConfig from "../pages/ControlPanel/LandingPage";
import LoginPageConfig from "../pages/ControlPanel/Login";
import RegisterPageConfig from "../pages/ControlPanel/Register";
import Polos from "../pages/Inscricoes/Polos/Polos";
import CriarPolo from "../pages/Inscricoes/Polos/CriarPolo";
import Cursos from "../pages/Inscricoes/Cursos/Cursos";
import CriarCurso from "../pages/Inscricoes/Cursos/CriarCurso";
import EditarCurso from "../pages/Inscricoes/Cursos/EditaCurso";
import EditarPolo from "../pages/Inscricoes/Polos/EditarPolo";
import CourseList from "../pages/Courses/CourseList";
import CoursePage from "../pages/Courses/CoursePage";
import CourseAdd from "../pages/Courses/CourseAdd";
import CourseEdit from "../pages/Courses/CourseEdit";
import LessonAdd from "../pages/Courses/CoursePage/CourseLessons/LessonAdd";
import LessonEdit from "../pages/Courses/CoursePage/CourseLessons/LessonEdit";
import UserAdd from "../pages/Courses/CoursePage/CourseUsers/UserAdd";
import RegistrationOptions from "../pages/Inscricoes/Registrations/Registrations";
import Registrations from "../pages/Inscricoes/Registrations/Registrations";
import ViewRegistrations from "../pages/Inscricoes/Registrations/ViewRegistrations";
import Profile from "../pages/Profile";
import ProfileEditAdmin from "../pages/ProfileEdit/Admin";
import CourseLandingPageConfig from "../pages/ControlPanel/CourseLandingPage";
import CoursesUserView from "../pages/Users/CoursesUserView";
import CategoryList from "../pages/Courses/Category/List";
import CategoryAdd from "../pages/Courses/Category/Add";
import CategoryEdit from "../pages/Courses/Category/Edit";
import PreRegisterList from "../pages/Inscricoes/PreRegister/List";
import MyList from "../pages/Users/MyList";
import RegistrationIndex from "../pages/Inscricoes/Registrations/RegistrationIndex";
import RoleBased from "components/Role/RoleBased";
import RoleBasedStyle from "components/Role/RoleBasedStyle";
import RoleRoute from "components/Role/RoleRoute";
import LessonView from "pages/Courses/CoursePage/CourseLessons/LessonView";
import CourseView from "pages/Courses/CourseView";
import CreateAccount from "pages/Users/Create";
import Logs from "pages/LogPages/Logs";
import SignatureChecker from "components/SignatureChecker";
import Authentication from "components/Authentication";
import TagList from "pages/Courses/Tags/List";
import TagAdd from "pages/Courses/Tags/Add";
import TagEdit from "pages/Courses/Tags/Edit";
import ModuleAdd from "pages/Courses/CoursePage/Modules/Add";
import ModuleEdit from "pages/Courses/CoursePage/Modules/Edit";
import ModuleView from "pages/Courses/CoursePage/Modules/View";
import ExerciseStatistics from "pages/ExerciseStatistics";
import BannerPrincipalList from "pages/ItecSite/BannerPrincipal/BannerPrincipalList";
import BannerPrincipalAdd from "pages/ItecSite/BannerPrincipal/BannerPrincipalAdd";
import BannerPrincipalEdit from "pages/ItecSite/BannerPrincipal/BannerPrincipalEdit";
import TestimonialList from "pages/RTSite/News/TestimonialList";
import TestimonialAdd from "pages/RTSite/News/TestimonialAdd";
import TestimonialEdit from "pages/RTSite/News/Testimonialedit";
import { Header as UserHeader } from "modules/Header/index.js";
import { loggedPages } from "modules/Header/data";
import GroupsList from "pages/Groups/List";
import GroupsAdd from "pages/Groups/Add";
import GroupsEdit from "pages/Groups/Edit";
import GroupsView from "pages/Groups/View";
import GroupUsersAdd from "pages/Groups/View/Users/Add";
import CourseGroupsAdd from "pages/Courses/CoursePage/Groups/Add";
import FirstContent from "pages/RTSite/Description/FirstContent";
import AdvertisingVideo from "pages/RTSite/AdvertisingVideo";
import AsideBannerAdd from "pages/RTSite/AsideBanner/Add";
import AboutUsPageConfig from "pages/ControlPanel/AboutUsPage";
// import TeamMembersList from 'pages/ControlPanel/TeamMembers/List';
// import TeamMemberAdd from 'pages/ControlPanel/TeamMembers/Add';
// import TeamMemberEdit from 'pages/ControlPanel/TeamMembers/Edit';
import MissionPageConfig from "pages/ControlPanel/MissionPage";
import EmailActivitiesList from "pages/LogPages/Emails/EmailActivities/List";
import AsideBannerList from "pages/RTSite/AsideBanner/List";
import AdquireCourse from "pages/Courses/AdquireCourse";
import HomeCourse from "pages/Courses/HomeCourse";
import PublicProfile from "pages/PublicProfile";
import CategoryView from "pages/Courses/Category/View";
import ActivateAccount from "pages/Users/ActivateAccount";
import SciencePlusList from "pages/SciencePlus/List";
import SciencePlusAdd from "pages/SciencePlus/Add";
import SciencePlusEdit from "pages/SciencePlus/Edit";
import CouponList from "pages/Courses/Coupons/List";
import FreeCoursesList from "pages/FreeCourses/List";
import CouponAdd from "pages/Courses/Coupons/Add";
import CouponEdit from "pages/Courses/Coupons/Edit";
import CouponUserList from "pages/Courses/Coupons/View";
import Dashboard from "pages/Dashboard";
import GroupCoursesAdd from "pages/Groups/View/Courses/Add";
import PolicyTermsForm from "pages/RTSite/PolicyTerms";
import PolicyTerms from "pages/PolicyTerms";
import TermsForm from "pages/RTSite/Terms";
import ForcedRoleAlert from "modules/ForcedRoleAlert";
import MyCourses from "pages/Users/MyCourses";
import RoutesCoursesUsers from "./Routes/Courses/Users";
import CourseGroupsEdit from "pages/Courses/CoursePage/Groups/Edit";
import FreeCoursesEdit from "pages/FreeCourses/Edit";
import FreeCoursesAdd from "pages/FreeCourses/Add";
import LeadsCourse from "pages/LogPages/Emails/LeadsCourse";
import LeadsCourseActivitiesList from "pages/LogPages/Emails/LeadsCourse/List";
import ConfigPaginaInicial from "pages/PagesConfig/PaginaInicial";
import ConfigSobreNos from "pages/PagesConfig/SobreNos";
import ConfigSaudeIntegrativa from "pages/PagesConfig/SaudeIntegrativa";
import ConfigLogin from "pages/PagesConfig/Login";
import ConfigCadastro from "pages/PagesConfig/Cadastro";
import SciencePlusReferencesList from "pages/SciencePlus/References/List";
import SciencePlusReferencesAdd from "pages/SciencePlus/References/Add";
import SciencePlusReferencesEdit from "pages/SciencePlus/References/Edit";
import DashFinanceiro from "pages/DashFinanceiro";
import Payment from "pages/Courses/AdquireCourse/Paymant";
import Paymant from "pages/Courses/AdquireCourse/Paymant";
import CourseFinancial from "pages/Courses/CourseFinancial";
import PaymentData from "pages/Users/Card/PaymentData";
import Orders from "pages/Users/Orders/Orders";
import ConfigMuralCursos from "pages/PagesConfig/MuralCursos";
import AccountsDisabledLog from "pages/LogPages/AccountsDisabled";
import CursosComprados from "pages/CursosComprados";
import CheckoutPage from "pages/CheckoutPage";
import ConfigMuralCursosNextflix from "pages/PagesConfig/MuralCursosNetflix";
import CoursePayment from "pages/Courses/CoursePayment";

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    )}
  />
);

const Home = (props) => {
  const forcedRole = useSelector((store) => store.AdminReducer.forcedRole);
  const [loading_home, setLoadingHome] = useState(true);
  const [image, setImage] = useState(true);

  const toggled = useSelector((store) => store.AppReducer.toggled);
  const collapsed = useSelector((store) => store.AppReducer.collapsed);
  const token = useSelector((store) => store.AppReducer.token);
  const user = useSelector((store) => store.AppReducer.user);
  const adminAsUser = useSelector((store) => store.AppReducer.adminAsUser);
  const snackbar = useSelector((store) => store.SnackbarReducer);
  const dispatch = useDispatch();

  const history = useHistory();

  const marginLeft =
    toggled == false || window.innerWidth <= 768
      ? 0
      : collapsed == false
      ? 270
      : 80;
  // const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    dispatch(mudarDados({ collapsed: checked }));
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    // setToggled(value);
    // console.log(value);
    dispatch(mudarDados({ toggled: value }));
  };

  const monted = useRef();

  const get_user = useCallback(() => {
    fetch(`${URL}api/auth/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.message == "Unauthenticated." || resp?.user?.temp==true) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            dispatch(logout());
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.error(erro);
            setTimeout(() => {
              get_user();
            }, 5000);
          } else {
            if (monted.current) {
              setLoadingHome(false);
            }

            localStorage.setItem("user", JSON.stringify(resp.user));
            dispatch(login({ token: token, user: resp.user }));

            // if (resp.user && resp.user.first_access == true) {
            //   history.replace("/change_password");
            // }
          }
        } catch (err) {
          setTimeout(() => {
            get_user();
          }, 5000);
          console.error(err);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          get_user();
        }, 5000);
      });
  }, [token]);

  useEffect(() => {
    console.log("forcedRole", forcedRole);
    if (forcedRole !== "Aluno" && forcedRole !== null) {
      if (history.location.pathname === "/") {
        history.replace("/dashboard");
      }
    }

    monted.current = true;

    const event = (e) => {
      if (window.innerWidth <= 768) {
        dispatch(mudarDados({ toggled: false, collapsed: false }));
      } else {
        dispatch(mudarDados({ toggled: true, collapsed: false }));
      }
    };
    window.addEventListener("resize", event);
    if (window.innerWidth <= 768) {
      dispatch(mudarDados({ toggled: false, collapsed: false }));
    } else {
      dispatch(mudarDados({ toggled: true, collapsed: false }));
    }
    get_user();

    return () => {
      window.removeEventListener("resize", event);
      monted.current = false;

      // Anything in here is fired on component unmount.
    };
  }, []);

  const HandlePath = (adminComponent, userPath, allowAdminAsUser = false) => {
    if (user.role == "Admin" || user.role == "Colaborador") {
      if (adminAsUser && allowAdminAsUser) return userPath;
      return adminComponent;
    } else return userPath;
  };
  // console.log(history.location.pathname);

  useEffect(() => {}, [sessionStorage.getItem("forcedRole")]);

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      <Authentication />
      <RoleBased roles={["Admin", "Professor", "Colaborador"]}>
        <Aside
          image={image}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      </RoleBased>
      <RoleBased roles={["Admin", "Professor", "Colaborador"]}>
        <Header
          // image={image}
          toggled={toggled}
          collapsed={collapsed}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          handleImageChange={handleImageChange}
        />
      </RoleBased>
      <RoleBased roles={["Aluno"]}>
        <UserHeader pages={loggedPages} />
      </RoleBased>
      <div className="content-page">
        <RoleBasedStyle
          className="content"
          defaultStyle={{
            transition: "all 0.3s ",
            paddingBottom: 100,
          }}
          hasRoleStyle={{
            marginLeft: marginLeft,
          }}
          roles={["Admin", "Professor", "Colaborador"]}
        >
          {loading_home == false && (
            <div>
              <Switch>
                {/* --------------------------Test-------------------------- */}
                <RoleRoute
                  roles={["Aluno"]}
                  exact
                  path={`${props.match.url}user/cards`}
                  render={(props2) => <PaymentData />}
                />

                {/* <RoleRoute
                  roles={["Aluno"]}
                  exact
                  path={`${props.match.url}user/orders`}
                  render={(props2) => <Orders />}
                /> */}

                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}dashboard`}
                  render={(props2) => <Dashboard />}
                  to={"/"}
                />
                <RoleRoute
                  roles={["Admin", "Colaborador"]}
                  exact
                  path={`${props.match.url}dashfinanceiro`}
                  render={(props2) => <DashFinanceiro />}
                  to={"/"}
                />
                <RoleRoute
                  roles={["Admin", "Colaborador"]}
                  exact
                  path={`${props.match.url}cursos-comprados`}
                  render={(props2) => <CursosComprados />}
                  to={"/"}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/depoimentos`}
                  render={(props2) => <TestimonialList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/depoimentos/criar`}
                  render={(props2) => <TestimonialAdd />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/depoimentos/:testimonial_id/editar`}
                  render={(props2) => <TestimonialEdit />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/descricao/primeiro-conteudo`}
                  render={(props2) => <FirstContent />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/bannerPrincipal-home`}
                  render={(props2) => <BannerPrincipalList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/bannerPrincipal-home/:banner_id/editar`}
                  render={(props2) => <BannerPrincipalEdit />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/bannerPrincipal-home/criar`}
                  render={(props2) => <BannerPrincipalAdd />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/advertising-video`}
                  render={(props2) => <AdvertisingVideo />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/aside-banner/add`}
                  render={(props2) => <AsideBannerAdd />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/aside-banner`}
                  render={(props2) => <AsideBannerList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/policy-terms`}
                  render={(props2) => <PolicyTermsForm />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}site-rt/terms`}
                  render={(props2) => <TermsForm />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}log/email`}
                  render={(props2) => <EmailsLog />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}log/email/activity/:email_id`}
                  render={(props2) => <EmailActivitiesList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}log/leads-course`}
                  render={(props2) => <LeadsCourse />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}log/leads-course/activity/:email_id`}
                  render={(props2) => <LeadsCourseActivitiesList />}
                />
                {/* <RoleBased roles={["Admin"]}> */}
                <RoleRoute
                  exact
                  path={`${props.match.url}log/accounts`}
                  render={(props2) => <AccountsLog />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}log/accounts-disabled`}
                  render={(props2) => <AccountsDisabledLog />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}accounts/create`}
                  render={(props2) => <CreateAccount />}
                />

                {/* </RoleBased> */}
                <RoleRoute
                  exact
                  path={`${props.match.url}logs`}
                  render={(props2) => <Logs />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}groups/list`}
                  render={(props2) => <GroupsList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}groups/add`}
                  render={(props2) => <GroupsAdd />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}groups/:group_id/edit`}
                  render={(props2) => <GroupsEdit />}
                />
                <RoleRoute
                  exact
                  roles={["Admin", "Professor", "Colaborador"]}
                  path={`${props.match.url}groups/:group_id/view`}
                  render={(props2) => <GroupsView />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}groups/:group_id/users/add`}
                  render={(props2) => <GroupUsersAdd />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}groups/:group_id/courses/add`}
                  render={(props2) => <GroupCoursesAdd />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}polos`}
                  render={(props2) => <Polos />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}polos/criar`}
                  render={(props2) => <CriarPolo />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}polos/editar/:id`}
                  render={(props2) => <EditarPolo />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}cursos`}
                  render={(props2) => <Cursos />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}cursos/criar`}
                  render={(props2) => <CriarCurso />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}cursos/editar/:id`}
                  render={(props2) => <EditarCurso />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}cursos/configuracao-lp/:course_name/:course_id`}
                  render={(props2) => <CourseLandingPageConfig />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}inscricoes`}
                  render={(props2) => <RegistrationIndex />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}inscricoes/view/:id`}
                  render={(props2) => <ViewRegistrations />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}pre-inscricoes/lista`}
                  render={(props2) => <PreRegisterList />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/general`}
                  render={(props2) => <GeneralConfig />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/landing-page`}
                  render={(props2) => <LandingPageConfig />}
                />
                {/* <RoleRoute
                  exact
                  path={`${props.match.url}page/config/login`}
                  render={(props2) => <LoginPageConfig />}
                /> */}
                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/register`}
                  render={(props2) => <RegisterPageConfig />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/about-us`}
                  render={(props2) => <AboutUsPageConfig />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/mission`}
                  render={(props2) => <MissionPageConfig />}
                />
                {/* <RoleRoute exact path={`${props.match.url}page/config/team-members`} render={props2 => <TeamMembersList/>}/>
                            <RoleRoute exact path={`${props.match.url}page/config/team-members/add`} render={props2 => <TeamMemberAdd/>}/>
                            <RoleRoute exact path={`${props.match.url}page/config/team-members/:member_id/edit`} render={props2 => <TeamMemberEdit/>}/> */}

                <Route
                  exact
                  path={`${props.match.url}minha-lista`}
                  render={(props2) => <MyList />}
                />
                <Route
                  exact
                  path={`${props.match.url}my-courses`}
                  render={(props2) => <MyCourses />}
                />
                <Route
                  exact
                  path={`${props.match.url}`}
                  render={(props2) => <CoursesUserView />}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}courses/list`}
                  render={(props2) => <CourseList />}
                  forcedRoleConfig={[{ role: "Aluno", to: "/teste" }]}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}courses/add`}
                  render={(props2) => <CourseAdd />}
                  to="/courses/list"
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}courses/edit/:id`}
                  render={(props2) => <CourseEdit />}
                  to="/courses/list"
                />
                <RoleRoute
                  roles={["Admin"]}
                  exact
                  path={`${props.match.url}courses/financial/:id`}
                  render={(props2) => <CourseFinancial />}
                  to="/courses/list"
                />
                <RoleRoute
                  roles={["Admin"]}
                  exact
                  path={`${props.match.url}courses/payments/:id`}
                  render={(props2) => <CoursePayment />}
                  to="/courses/list"
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}courses/add-user/:course_id`}
                  render={(props2) => <UserAdd />}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}courses/view/:id`}
                  render={(props2) => <CoursePage {...props2} />}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}courses/view/:course_id/groups/add`}
                  render={(props2) => <CourseGroupsAdd />}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}courses/view/:course_id/groups/edit/:group_id`}
                  render={(props2) => <CourseGroupsEdit />}
                />

                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}courses/exercises-statistics/:course_id/:user_id`}
                  render={() => <ExerciseStatistics />}
                />

                {/* <Route path={`${props.match.url}courses`} component={(props) => <RoutesCoursesUsers {...props}/>}/> */}
                <Route
                  exact
                  path={`${props.match.url}courses/:course_id/lesson/:lesson_id`}
                  render={(props2) => <LessonView />}
                />
                <Route
                  exact
                  path={`${props.match.url}courses/home/:course_id`}
                  render={(props2) => <HomeCourse />}
                />
                <Route
                  exact
                  path={`${props.match.url}courses/adquire/:course_id`}
                  render={(props2) => <AdquireCourse />}
                />

                <Route
                  exact
                  path={`${props.match.url}courses/payment/:course_id`}
                  render={(props2) => <CheckoutPage />}
                />
                {/* <Route
                  exact
                  path={`${props.match.url}courses/payment/:course_id`}
                  render={(props2) => <Payment />}
                /> */}

                <Route
                  exact
                  path={`${props.match.url}courses/:course_id`}
                  render={(props2) => <CourseView />}
                />
                <Route
                  exact
                  path={`${props.match.url}courses/:course_id/:file_id`}
                  render={(props2) => <CourseView />}
                />

                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}modules/:course_id/new`}
                  render={(props2) => <ModuleAdd />}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}modules/:module_id/:course_id/edit`}
                  render={(props2) => <ModuleEdit />}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}modules/:module_id/:course_id`}
                  render={(props2) => <ModuleView />}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}modules/:module_id/:course_id/lesson/new`}
                  render={(props2) => <LessonAdd />}
                />
                <RoleRoute
                  roles={["Admin", "Professor", "Colaborador"]}
                  exact
                  path={`${props.match.url}modules/:module_id/:course_id/lesson/:lesson_id/edit`}
                  render={(props2) => <LessonEdit />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}free-courses/list`}
                  render={(props2) => <FreeCoursesList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}free-courses/novo`}
                  render={(props2) => <FreeCoursesAdd />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}free-courses/editar/:category_id`}
                  render={(props2) => <FreeCoursesEdit />}
                />
                {/* <RoleRoute exact path={`${props.match.url}categories/:category_id/view`} render={props2 => <CategoryView/>}/> */}

                <RoleRoute
                  exact
                  path={`${props.match.url}categorias`}
                  render={(props2) => <CategoryList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}categorias/novo`}
                  render={(props2) => <CategoryAdd />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}categorias/editar/:category_id`}
                  render={(props2) => <CategoryEdit />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}categories/:category_id/view`}
                  render={(props2) => <CategoryView />}
                />

                <RoleRoute
                  roles={["Admin", "Colaborador", "Professor"]}
                  exact
                  path={`${props.match.url}tag`}
                  render={(props2) => <TagList />}
                />
                <RoleRoute
                  roles={["Admin", "Colaborador", "Professor"]}
                  exact
                  path={`${props.match.url}tag/new`}
                  render={(props2) => <TagAdd />}
                />
                <RoleRoute
                  roles={["Admin", "Colaborador", "Professor"]}
                  exact
                  path={`${props.match.url}tag/:tag_id/edit`}
                  render={(props2) => <TagEdit />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}coupon`}
                  render={(props2) => <CouponList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}coupon/new`}
                  render={(props2) => <CouponAdd />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}coupon/:coupon_id/edit`}
                  render={(props2) => <CouponEdit />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}coupon/:coupon_id/users`}
                  render={(props2) => <CouponUserList />}
                />
                <RoleRoute
                  exact
                  path={`${props.match.url}coupon/:coupon_id/users/add`}
                  render={(props2) => <CouponEdit />}
                />

                <Route
                  exact
                  path={`${props.match.url}profile/view/:user_id/public`}
                  render={(props2) => <PublicProfile />}
                />
                <Route
                  exact
                  path={`${props.match.url}profile/view/:user_id`}
                  render={(props2) => <Profile />}
                />
                <Route
                  exact
                  path={`${props.match.url}profile/edit/:user_id`}
                  render={(props2) => <ProfileEditAdmin />}
                />

                <Route
                  exact
                  path={`${props.match.url}science-plus/list`}
                  render={(props2) => <SciencePlusList />}
                />
                <Route
                  exact
                  path={`${props.match.url}science-plus/add`}
                  render={(props2) => <SciencePlusAdd />}
                />
                <Route
                  exact
                  path={`${props.match.url}science-plus/:post_id/edit`}
                  render={(props2) => <SciencePlusEdit />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/pagina-inicial`}
                  render={(props2) => <ConfigPaginaInicial />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/sobre-nos`}
                  render={(props2) => <ConfigSobreNos />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/saude-integrativa`}
                  render={(props2) => <ConfigSaudeIntegrativa />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/mural-de-cursos`}
                  render={(props2) => <ConfigMuralCursos />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/mural-de-cursos-netflix`}
                  render={(props2) => <ConfigMuralCursosNextflix />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/login`}
                  render={(props2) => <ConfigLogin />}
                />

                <RoleRoute
                  exact
                  path={`${props.match.url}page/config/cadastro`}
                  render={(props2) => <ConfigCadastro />}
                />

                <Route
                  exact
                  path={`${props.match.url}science-plus/references/list`}
                  render={(props2) => <SciencePlusReferencesList />}
                />
                <Route
                  exact
                  path={`${props.match.url}science-plus/references/add`}
                  render={(props2) => <SciencePlusReferencesAdd />}
                />
                <Route
                  exact
                  path={`${props.match.url}science-plus/references/:post_id/edit`}
                  render={(props2) => <SciencePlusReferencesEdit />}
                />
                <Route
                  exact
                  path={`${props.match.url}test`}
                  render={(props2) => <Paymant />}
                />
              </Switch>
            </div>
          )}
          {loading_home == true && (
            <div className="row">
              {" "}
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ marginTop: "2rem" }}
              >
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          )}
        </RoleBasedStyle>
        <SignatureChecker />
        <ForcedRoleAlert />
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Home;
